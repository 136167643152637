import { defineStore } from 'pinia'
import { apiHandler, ServerResponse } from '@/api/ApiHandler'
import { Wagon } from '@/model/Wagon'
import { pushItem, pushItems, UpdateFieldPayload } from '.'
import { Toaster } from '@/common/Toaster'

export interface StoreStateWagon {
  wagons: Wagon[]
  currentId: string | undefined
}

export const useStoreWagon = defineStore('wagon', {
  state: (): StoreStateWagon => ({
    wagons: [],
    currentId: undefined,
  }),
  getters: {
    wagon: (state) => state.wagons.find(w => w.id === state.currentId),
  },
  actions: {
    async get(
      id: string,
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'entities/trains/wagon/wagonManager.php?search=true',
        { commandeIdNull: 'full', id },
      )
      this.wagons = pushItem(this.wagons, response.wagons[0])
      return response
    },
    async search(
      data = {},
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'entities/trains/wagon/wagonManager.php?search=true',
        { commandeIdNull: 'full', ...data },
      )
      this.wagons = pushItems(this.wagons, response.wagons)
      return response
    },
    async updateField(
      { name, value, lastValue, id }: UpdateFieldPayload,
    ): Promise<ServerResponse | boolean> {
      if (lastValue === value || (!value && !lastValue)) return true
      const response = await apiHandler.put(
        'entities/trains/wagon/wagonManager.php?field=true',
        { name, value, id },
      )
      Toaster.toast({
        type: response.success ? 'success' : 'error',
        message: response.message,
      })
      if (response.success) {
        this.wagons = pushItem(this.wagons, response.wagon)
      }
      return response
    },
  },
})
