import { defineStore } from 'pinia'
import { apiHandler, ServerResponse } from '@/api/ApiHandler'
import { User } from '@/model/User'
import { extractExpiration } from '@/common/util'
import { Commande } from '@/model/Commande'
import { pushItem, pushItems, removeItem } from '.'

export interface StoreStateAuth {
  users: User[]
  token: string
  loggedUser?: User
}

export const useStoreAuth = defineStore('auth', {
  state: (): StoreStateAuth => ({
    users: [],
    get loggedUser(): User | undefined {
      return localStorage.getItem('loggedUser') ?
        JSON.parse(localStorage.getItem('loggedUser') || '{}') :
        undefined
    },
    set loggedUser(loggedUser: User | undefined) {
      localStorage.setItem('loggedUser', JSON.stringify(loggedUser || ''))
    },
    get token(): string {
      return localStorage.getItem('token') || ''
    },
    set token(token: string) {
      localStorage.setItem('token', token || '')
    },
  }),
  getters: {
    isLogged: (state): boolean => {
      if (!state.token) {
        return false
      }
      const creationDate = extractExpiration(state.token)
      return new Date().getTime() < creationDate * 1000 + 60 * 60 * 10 * 1000
    },
    isAdmin: (state): boolean => {
      return !!state.loggedUser?.admin
    },
  },
  actions: {
    async login(
      { username, password }: { username: string; password: string },
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'account/accountManager.php?login=true',
        { username, password },
      )
      if (response.success) {
        this.token = response.token
        this.loggedUser = {
          ...response.user, id: response.user.uid,
        }
      }
      return response
    },
    logout(): void {
      this.token = ''
    },
    async deleteUser(
      { id, username }: { id: number; username: string },
    ): Promise<ServerResponse> {
      const response = await apiHandler.delete(
        'account/accountManager.php?username=' + username,
      )
      if (response.success) {
        this.users = removeItem(this.users, id)
      }
      return response
    },
    async search(
      data = {},
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'account/accountManager.php?search=true',
        data,
      )
      // console.log(this.users, response.accounts)
      this.users = pushItems(this.users, response.accounts.map((a: any) => ({ ...a, id: a.uid })))
      return response
    },
    async getById(id: number): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'account/accountManager.php?search=true',
        { id: id },
      )
      this.users = pushItem(this.users, {
        ...response.accounts[0], id: response.accounts[0].uid,
      })
      return response
    },
    async addUserCommex(user: User): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'account/accountManager.php?addcommex=true',
        user,
      )
      const userResponse = await apiHandler.post(
        'account/accountManager.php?search=true',
        {
          id: response.user,
        },
      )
      if (userResponse.accounts) {
        this.users = pushItem(this.users, {
          ...userResponse.accounts[0], id: userResponse.accounts[0].uid,
        })
      }
      return response
    },
    async updateCommandes({ user, commandes }: { user: User; commandes: Commande[] }): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'account/accountManager.php?commandes=true',
        { user, commandes },
      )
      return response
    },
  },
})
