import { defineStore } from 'pinia'
import { apiHandler, ServerResponse } from '@/api/ApiHandler'
import { ItemImage, pushItem, pushItems, removeItem, UpdateFieldPayload } from '.'
import { Toaster } from '@/common/Toaster'
import { Amiante } from '@/model/Amiante'

export interface StoreStateAmiante {
  amiantes: Amiante[]
  currentId: number | undefined
  images: ItemImage[]
}

export interface PayloadAmianteUpdateFields {
  amiante: Amiante
  wagonIds: string[]
  fields: unknown
  emptyField: unknown
}

export const useStoreAmiante = defineStore('amiante', {
  state: (): StoreStateAmiante => ({
    amiantes: [],
    currentId: undefined,
    images: [],
  }),
  getters: {
    // amiante: (state) => state.amiantes.find(c => c.id === state.currentId),
  },
  actions: {
    async getById(
      id: number,
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'commex/amiante/amianteManager.php?search=true',
        { id },
      )
      this.amiantes = pushItem(this.amiantes, response.amiantes[0])
      // commit(AmianteStoreMutations.SET_AMIANTE, response.amiantes[0])
      return response
    },
    async getByWagon(
      wagonId: string,
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'commex/amiante/amianteManager.php?search=true',
        { wagonId },
      )
      console.log(response)
      this.amiantes = pushItem(this.amiantes, response.amiantes[0])
      // commit(AmianteStoreMutations.SET_AMIANTE, response.amiantes[0])
      return response
    },
    async register(
      data: Amiante,
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'commex/amiante/amianteManager.php',
        data,
      )
      if (response.success) {
        this.amiantes = pushItem(this.amiantes, response.amiantes[0])
      } else {
        console.log(response)
      }
      // commit(AmianteStoreMutations.SET_AMIANTE, response.amiantes[0])
      return response
    },
    async search(
      data = {},
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'commex/amiante/amianteManager.php?search=true',
        { ...data },
      )
      this.amiantes = pushItems(this.amiantes, response.amiantes)
      // commit(AmianteStoreMutations.SET_AMIANTES, response.amiantes || [])
      return response
    },
    async getImages(
      wagonId: string,
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'commex/amiante/amianteManager.php?getimages=true',
        { wagonId },
      )
      console.log(response)
      if (response.success) {
        this.images = pushItems(this.images, response.images.map((i: any) => ({ ...i, wagonId, id: `${wagonId}-${i.name}` })))
        console.log(this.images)
      }
      // commit(AmianteStoreMutations.SET_IMAGES, response.images || '')
      return response
    },
    async saveImage(
      wagonId: string, name: string, base64: string,
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'commex/amiante/amianteManager.php?image=true',
        { wagonId, name, base64 },
      )
      console.log(response)
      if (response.success) {
        this.images = pushItem(this.images, { name: name, base64, wagonId, id: `${wagonId}-${name}` })
      }
      return response
    },
    async saveAllImages(
      wagonIds: string[], name: string, base64: string,
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'commex/amiante/amianteManager.php?allimages=true',
        { wagonIds, name, base64 },
      )
      Toaster.toast({
        type: response.success ? 'success' : 'error',
        message: response.message,
      })
      return response
    },
    async deleteImage(
      wagonId: string, name: string,
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'commex/amiante/amianteManager.php?deleteimage=true',
        { wagonId, name },
      )
      Toaster.toast({
        type: response.success ? 'success' : 'error',
        message: response.message,
      })

      if (response.success) {
        this.images = removeItem(this.images, `${wagonId}-${name}`)
      }

      return response
    },
    async deleteAllImages(
      wagonIds: string[],
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'commex/amiante/amianteManager.php?deleteallimages=true',
        { wagonIds },
      )
      Toaster.toast({
        type: response.success ? 'success' : 'error',
        message: response.message,
      })
      this.images = []
      // commit(AmianteStoreMutations.REMOVE_IMAGE, name)
      return response
    },
    async updateField(
      { value, lastValue, id, name }: UpdateFieldPayload,
    ): Promise<ServerResponse> {
      if (lastValue === value || (!value && !lastValue)) return {} as any
      const response = await apiHandler.put(
        'commex/amiante/amianteManager.php?field=true',
        { name, value, id },
      )
      Toaster.toast({
        type: response.success ? 'success' : 'error',
        message: response.message,
      })
      if (response.success) {
        this.amiantes = pushItem(this.amiantes, response.amiante)
        // commit(AmianteStoreMutations.SET_AMIANTE, response.amiante || {})
      }
      return response
    },
    async updateAllFields(
      { amiante, wagonIds, fields, emptyField }: PayloadAmianteUpdateFields,
    ): Promise<ServerResponse> {
      const response = await apiHandler.put(
        'commex/amiante/amianteManager.php?allfield=true',
        { amiante, wagonIds, fields, emptyField },
      )
      Toaster.toast({
        type: response.success ? 'success' : 'error',
        message: response.message,
      })
      return response
    },
  },
})
