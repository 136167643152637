import { Commande } from '@/model/Commande'
import { Wagon } from '@/model/Wagon'
import router from '@/router'
import { ItemImage } from '@/store'

import { saveAs } from 'file-saver'
import { Toaster } from './Toaster'

export const ELEMENT_PER_PAGE = 20

export const idToString = (_id: string | undefined): string => {
  if (_id === undefined || _id === null || _id === '') {
    return ''
  }

  let res = ''
  res += _id.toString().slice(0, 2)
  res += ' ' + _id.toString().slice(2, 4)
  res += ' ' + _id.toString().slice(4, 7)
  res += ' ' + _id.toString().slice(7, 11)
  res += ' - ' + _id.toString().slice(11, 12)

  return res
}

export const extractExpiration = (token: string): number => {
  if (!token) {
    return 0
  }
  const base64Url = token.split('.')[1]
  if (!base64Url) {
    return 0
  }
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  const tokendata = JSON.parse(window.atob(base64) || '')
  if (tokendata && tokendata.creationDate) {
    return tokendata.creationDate
  }
  return 0
}

export const goTo = (path: string) => {
  router.push(path)
}

export const goBack = () => {
  router.back()
}

export const searchCommande = (commandes: Commande[], value: string) => {
  return commandes.filter(c => {
    return c.name?.match(value) ||
      c.location?.match(value) ||
      `${c.wagonCount}`?.match(value)
  })
}

export const searchWagon = (wagons: Wagon[], value: string) => {
  return wagons.filter(w => {
    return (
      w.id?.match(value.replace(/ /g, '')) ||
      w.owner?.match(value) ||
      w.type?.match(value) ||
      w.set?.match(value) ||
      w.railway?.match(value) ||
      w.sale?.match(value)
    )
  })
}

export const downloadFile = (base64: string, filename: string) => {
  saveAs(base64, filename)
}

export const downloadBundle = (images: ItemImage[]): void => {
  if (!images) {
    Toaster.toast({
      message: `Aucun fichier à télécharger`,
      type: 'warning',
    })
    return
  }
  let i = 0
  for (const image of images) {
    i++
    saveAs(image.base64, image.name)
  }
  Toaster.toast({
    message: `Téléchargement de ${i} fichier${i > 1 ? 's' : ''}`,
    type: 'success',
  })
}
