export enum Detection {
  detected = 'detected',
  undetected = 'undetected',
}
export enum Verification {
  verified = 'verified',
  unverified = 'unverified',
}

export interface Amiante {
  id?: number
  wagonId?: string
  detection?: Detection
  verification?: Verification
  anneeFabrication?: string
  fabricant?: string
  numeroFacture?: string
  prereportnumber?: string
  reportnumber?: string
  bsda?: string

  countImages?: boolean
  hasReport?: boolean
  hasPreReport?: boolean
  hasReportAnalyse?: boolean
  hasPreReportAnalyse?: boolean
  hasReportOther?: boolean
  hasAmiante?: boolean
  hasDocumentation?: boolean
}
