import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import { Toaster } from '@/common/Toaster'

export const downloadExcel = (buffer: ExcelJS.Buffer, filename: string = 'file') => {
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  saveAs(blob, filename?.match('.xlsx') ? filename : `${filename}.xlsx`)
  Toaster.toast('Téléchargement terminé')
}

export const alignementCenter: Partial<ExcelJS.Alignment> = {
  vertical: 'middle',
  horizontal: 'center',
}

export const fontHeader: Partial<ExcelJS.Font> = {
  bold: true,
  size: 14,
}

export const fontBody: Partial<ExcelJS.Font> = {
  bold: false,
  size: 12,
}

export const borderHeader: Partial<ExcelJS.Borders> = {
  top: { style: 'medium' },
  left: { style: 'medium' },
  bottom: { style: 'medium' },
  right: { style: 'medium' },
}

export const borderBody: Partial<ExcelJS.Borders> = {
  top: { style: 'thin' },
  left: { style: 'thin' },
  bottom: { style: 'thin' },
  right: { style: 'thin' },
}
