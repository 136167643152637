import { createRouter, createWebHistory, RouteLocationRaw, RouteRecordRaw } from 'vue-router'

import ViewMain from '../views/ViewMain.vue'
import ViewUsers from '../views/home/ViewUsers.vue'
import ViewWagons from '../views/home/ViewWagons.vue'
import ViewWagon from '../views/home/ViewWagon.vue'
import ViewUser from '../views/home/ViewUser.vue'
import ViewHome from '../views/home/ViewHome.vue'
import ViewAccount from '../views/home/ViewAccount.vue'
import ViewCommandes from '../views/home/ViewCommandes.vue'
import ViewCommande from '../views/home/ViewCommande.vue'
import ViewCommandeEdit from '../views/home/ViewCommandeEdit.vue'
import ViewCommandeDownload from '../views/home/ViewCommandeDownload.vue'
import NotFound from '../views/ViewNotFound.vue'
import { useStoreAuth } from '@/store/auth'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: ViewMain,
    children: [
      {
        path: '',
        redirect: 'home',
      },
      {
        path: 'home',
        name: 'Home',
        component: ViewHome,
      },
      {
        path: 'account',
        name: 'Account',
        component: ViewAccount,
      },
      {
        path: 'users',
        name: 'Users',
        component: ViewUsers,
      },
      {
        path: 'commandes',
        name: 'Commandes',
        component: ViewCommandes,
      },
      {
        path: 'commandes/:id',
        name: 'Commande',
        component: ViewCommande,
      },
      {
        path: 'commandes/:id/edit',
        name: 'CommandeEdit',
        component: ViewCommandeEdit,
      },
      {
        path: 'commandes/:id/download',
        name: 'CommandeDownload',
        component: ViewCommandeDownload,
      },
      {
        path: 'wagons',
        name: 'Wagons',
        component: ViewWagons,
      },
      {
        path: 'wagons/:id',
        name: 'Wagon',
        component: ViewWagon,
      },
      {
        path: 'users/:id',
        name: 'User',
        component: ViewUser,
      },
      {
        path: ':catchAll(.*)',
        component: NotFound,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Login',
    },
    component: () => import('../views/auth/ViewLogin.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const storeAuth = useStoreAuth()

  const isLogged = storeAuth.isLogged
  const isAdmin = storeAuth.isAdmin
  if (to.name !== 'Login' && !isLogged) {
    next({ name: 'Login', params: { backurl: to.fullPath } })
  } else if (!isAdmin && (to.name === 'Users' || to.name === 'User')) {
    next({ name: 'Home' })
  } else {
    next()
  }
})

export const goTo = (path: string | RouteLocationRaw) => {
  router.push(path)
}

export const openNewTab = (address: string | undefined) => {
  window.open(`${address}`, '_blank')
}

export const goBack = () => {
  router.back()
}

export const reload = () => {
  window.location.reload()
}
export default router
