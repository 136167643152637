import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import '@digithia/ui'
import '@digithia/input/text'
import '@digithia/input/number'
import '@digithia/input/file'
import '@digithia/input/select'
import '@digithia/input/radio'
import '@digithia/input/checkbox'
import '@digithia/input/password'

import { createPinia } from 'pinia'
const pinia = createPinia()

const app = createApp(App)
  .use(pinia)
  .use(router)

const requireComponent = require.context(
  './components/base',
  false,
  /Base[A-Z]\w+\.(vue|js)$/,
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = (fileName.split('/').pop() || '').replace(/\.\w+$/, '')
  app.component(componentName, componentConfig.default)
})

app.mount('#app')
