export interface DigithiaTableOptions<T> {
  /**
   * Columns to display
   */
  columns?: DigithiaTableColumnOptions<T>[]
  /**
   * Header options
   */
  header?: {
    pagination?: boolean
    beforeSlot?: string
    afterSlot?: string
  }
  /**
   * Footer options
   */
  footer?: {
    pagination?: boolean
    beforeSlot?: string
    afterSlot?: string
  }
  /**
   * Pagination options
   */
  paginationOptions?: {
    currentPage?: number
    elementsPerPage?: number
    countElements?: boolean
  }
  /**
   * Enable search field
   */
  enableSearch?: boolean
  /**
   * Specify search options
   */
  search?: {
    label?: string
    hint?: string
    caseSensitive?: boolean
  }
  /**
   * Enable sort behavior
   */
  enableSort?: boolean
  /**
   * Specify sort options
   */
  sort?: {
    multi?: boolean
    cancelable?: boolean
  }
  /**
   * Default message to display when no data is available
   */
  noDataMessage?: string
  /**
   * Default value to display when no data is available
   */
  defaultValue?: string
  /**
   * Default table alignement
   */
  defaultAlign?: 'left' | 'right' | 'center'
  classTable?: (item: T) => string
  classWrapper?: (item: T) => string
  classRow?: (item: T) => string
}

export interface DigithiaTableColumnOptions<T> {
  /**
   * Column unique identifier
   */
  ref: string
  /**
   * Column value for a given item to display
   */
  getValue?: (item: T) => string
  /**
   * The column header label
   */
  label?: string
  /**
   * Default value to display if `getValue` returns an empty string
   */
  defaultValue?: string
  /**
   * Table Header options
   */
  header?: {
    /**
     * Adds a tooltip to the header
     */
    tooltip?: string
    /**
     * Adds a slot to the header with the given name
     */
    slot?: string
    /**
     * Adds a class to the header
     */
    class?: string
    /**
     * Keeps the header in one line
     */
    noBreak?: boolean
    /**
     * Makes the header font bold
     */
    bold?: boolean
    /**
     * Sets the alignment of the header
     */
    align?: 'left' | 'right' | 'center'
    /**
     * Handles click events on the header
     */
    click?: (event: MouseEvent) => void
  }
  /**
   * Makes the column sortable
   */
  sortable?: boolean
  /**
   * Controls sorting behavior
   */
  sortOptions?: DigithiaTableSortableOptions<T>
  /**
   * Makes the column searchable
   */
  searchable?: boolean
  /**
   * Controls searching behavior
   */
  searchOptions?: DigithiaTableSearchableOptions<T>
  /**
   * Makes the column sticky
   */
  fixed?: boolean
  /**
   * Controls column width
   */
  width?: string
  body?: {
    class?: (item: T) => string
    align?: 'left' | 'right' | 'center'
    bold?: boolean
    noBreak?: boolean
    tooltip?: (item: T) => string
    click?: (event: MouseEvent, item: T) => void
    slot?: string
  }
}

export interface DigithiaSlotValue<T> {
  item: T
  rowIndex: number
  colIndex: number
}

export interface DigithiaTableSortableOptions<T> {
  type?: 'text' | 'number' | 'date' | 'boolean'
  getValue?: (item: T) => string
  customSort?: (a: unknown, b: unknown, order: boolean) => number
}

export interface Sortable<T> {
  filter: string
  type: 'text' | 'number' | 'date' | 'boolean'
  order: 'desc' | 'asc'
  getValue: (item: T) => string
  customSort?: (a: unknown, b: unknown, order: boolean) => number
}

export interface DigithiaTableSearchableOptions<T> {
  getValue?: (item: T) => string
  customSearch?: (value: string, search: string) => boolean
}

export interface Searchable<T> {
  getValue: (item: T) => string
  customSearch?: (value: string, search: string) => boolean
}

export const searchItems = <T>(
  data: T[],
  options: Searchable<T>[],
  search: string,
) => {
  return data.filter((w) => {
    const conditions = []

    for (const option of options) {
      const value = option.getValue(w)
      if (option.customSearch) {
        conditions.push(option.customSearch(value, search))
      } else {
        conditions.push(
          value?.toLocaleLowerCase().match(search.toLocaleLowerCase()),
        )
      }
    }

    return conditions.some((c) => c)
  })
}

export const sortItems = <T>(data: T[], options: Sortable<T>[]) => {
  return data.sort((a, b) => {
    let res = 0

    for (const option of options) {
      const valueA = option.getValue(a)
      const valueB = option.getValue(b)

      if (!option.type || option.type === 'text') {
        res = sortString(valueA, valueB, option.order === 'desc')
      } else if (option.type === 'number') {
        res = sortNumber(valueA || '', valueB || '', option.order === 'desc')
      } else if (option.type === 'date') {
        res = sortDate(valueA || '', valueB || '', option.order === 'desc')
      } else if (option.type === 'boolean') {
        res = sortBoolean(valueA || '', valueB || '', option.order === 'desc')
      } else {
        res = option.customSort ?
          option.customSort(valueA, valueB, option.order === 'desc') :
          0
      }

      if (res !== 0) {
        break
      }
    }

    return res
  })
}

export const sortString = (
  a: string | undefined,
  b: string | undefined,
  order: boolean = false,
): number => {
  if (!a && b) return 1
  if (!b && a) return -1
  if (order) {
    return b?.toString().localeCompare(a?.toString() || '') || 0
  } else {
    return a?.toString().localeCompare(b?.toString() || '') || 0
  }
}

export const sortDate = (
  a: string | undefined,
  b: string | undefined,
  order: boolean = false,
): number => {
  if (!a && b) return 1
  if (!b && a) return -1
  if (order) {
    return (a || 0) > (b || 0) ? -1 : 1
  } else {
    return (a || 0) > (b || 0) ? 1 : -1
  }
}

export const sortBoolean = (
  a: string | undefined,
  b: string | undefined,
  order: boolean = false,
): number => {
  if (order) {
    if (!a && b) return 1
    if (!b && a) return -1
  } else {
    if (!a && b) return -1
    if (!b && a) return 1
  }
  return 0
}

export const sortNumber = (
  a: string | undefined,
  b: string | undefined,
  order: boolean = false,
): number => {
  if (order) {
    return +(a || 0) - +(b || 0)
  } else {
    return +(b || 0) - +(a || 0)
  }
}
