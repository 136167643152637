<template>
  <router-view />
  <dig-ui-toaster bottom></dig-ui-toaster>
</template>

<script setup lang="ts">
</script>

<style lang="scss">
#app {
  --commex-color-grey: rgb(64, 68, 68);
  --commex-color-grey-50: rgb(64, 68, 68, 0.5);
  --commex-color-red: rgb(230, 81, 68);
  --commex-color-green: rgb(45, 155, 54);
  --commex-color-red-transparent-3: rgb(230, 81, 68, 0.3);
  --commex-color-red-transparent-2: rgb(230, 81, 68, 0.2);
  --commex-color-red-transparent-1: rgb(230, 81, 68, 0.1);
  --commex-color-white: #fff;
  --commex-color-grey-14: #eee;
  --commex-color-grey-13: #ddd;
  --commex-color-grey-12: #ccc;
  --commex-color-grey-11: #bbb;
  --commex-color-grey-10: #aaa;
  --commex-color-grey-9: #999;
  --commex-color-grey-8: #888;
  --commex-color-grey-7: #777;
  --commex-color-grey-6: #666;
  --commex-color-grey-5: #555;
  --commex-color-grey-4: #444;
  --commex-color-grey-3: #333;
  --commex-color-grey-2: #222;
  --commex-color-grey-1: #111;
  --commex-color-orange: #ed9711;

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--commex-color-grey);

  .pagination-container {
    margin: 20px 0px;
    text-align: center;
  }

  dig-ui-title {
    --dig-ui-title-color: var(--commex-color-grey);
    --dig-ui-title-line-color: var(--commex-color-grey-50);
  }

  .title-center {
    --dig-ui-title-text-align: center;
  }

  dig-ui-tabset {
    --dig-ui-tabset-title-selected-background: var(--commex-color-red-transparent-2);
    --dig-ui-tabset-title-hover-background: var(--commex-color-red-transparent-1);
    --dig-ui-tabset-title-hover-selected-background: var(--commex-color-red-transparent-3);
  }
}

dig-ui-standard-button {
  --dig-ui-standard-button-background-color: var(--commex-color-red);
}

dig-ui-standard-button.cancel {
  --dig-ui-standard-button-background-color: var(--commex-color-grey);
}

dig-ui-login-card {
  --dig-ui-login-card-font-family: Avenir, Helvetica, Arial, sans-serif;
}

dig-ui-badge {
  margin: 4px;
  --dig-ui-badge-text-color: var(--commex-color-white);
  --dig-ui-badge-background-color: var(--commex-color-grey);
  --dig-ui-badge-border-radius: 20px;
  --dig-ui-badge-vertical-padding: 4px;
  --dig-ui-badge-horizontal-padding: 10px;
}

hr {
  border: 0.1px solid var(--commex-color-grey-12);
}

.buttons-container {
  padding: 0px;
  text-align: center;

  dig-ui-standard-button {
    margin: 10px;
  }
}

.pagination-container {
  margin: 20px 0px;
  text-align: center;
}

.ctrl-buttons {
  margin: 4px;
  padding: 0;
  display: inline-flex;
  float: right;

  .icon-button {
    margin-left: 12px;
  }
}

.no-data-container {
  text-align: center;
  padding: 12px 6px;
}

.center {
  text-align: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.margin-button {
  dig-ui-standard-button {
    margin: 4px;
  }
}

.margin-top {
  margin-top: 10px;
}

.badge-small {
  margin: 2px;
  --dig-ui-badge-text-color: var(--commex-color-white);
  --dig-ui-badge-font-size: 10px;
  --dig-ui-badge-border-radius: 20px;
  --dig-ui-badge-vertical-padding: 2px;
  --dig-ui-badge-horizontal-padding: 6px;
  // display: block;
}

.badge-green {
  --dig-ui-badge-background-color: green;
}

.badge-red {
  --dig-ui-badge-background-color: red;
}

.badge-purple {
  --dig-ui-badge-background-color: purple;
}

.badge-blue {
  --dig-ui-badge-background-color: blue;
}

.badge-yellow {
  --dig-ui-badge-background-color: yellow;
  --dig-ui-badge-text-color: black;
}

:root,
body {
  margin: 0;
  padding: 0;
}
</style>
