import ExcelJS from 'exceljs'
import { idToString } from '@/common/util'
import { Commande } from '@/model/Commande'
import { Wagon } from '@/model/Wagon'
import { alignementCenter, borderBody, borderHeader, downloadExcel, fontBody, fontHeader } from './ExcelManager'

export const downloadWorkbookCommande = async (
  wagons: Wagon[],
  commande: Commande | undefined,
) => {
  const workbook = new ExcelJS.Workbook()

  generateWorkbookCommande(workbook, wagons, commande)

  downloadExcel(await workbook.xlsx.writeBuffer(), commande?.name)
}

const generateWorkbookCommande = (
  workbook: ExcelJS.Workbook, wagons: Wagon[], commande: Commande | undefined,
) => {
  const sheet = workbook.addWorksheet('Commande')
  let rowIndex = 1

  rowIndex = writeHeader(sheet, rowIndex)

  for (const wagon of wagons) {
    rowIndex = writeRow(sheet, rowIndex, wagon, commande)
  }
}

const writeHeader = (sheet: ExcelJS.Worksheet, rowIndex: number): number => {
  sheet.getRow(rowIndex).height = 20
  sheet.getRow(rowIndex).alignment = alignementCenter
  sheet.getRow(rowIndex).font = fontHeader
  sheet.getCell(`A${rowIndex}`).value = 'Commande'
  sheet.getCell(`A${rowIndex}`).border = borderHeader
  sheet.getCell(`B${rowIndex}`).value = 'Numéro wagon'
  sheet.getCell(`B${rowIndex}`).border = borderHeader
  sheet.getCell(`C${rowIndex}`).value = 'Propriétaire'
  sheet.getCell(`C${rowIndex}`).border = borderHeader
  sheet.getCell(`D${rowIndex}`).value = 'Type/Série'
  sheet.getCell(`D${rowIndex}`).border = borderHeader
  sheet.getCell(`E${rowIndex}`).value = 'Voie'
  sheet.getCell(`E${rowIndex}`).border = borderHeader
  sheet.getCell(`F${rowIndex}`).value = 'Zone'
  sheet.getCell(`F${rowIndex}`).border = borderHeader
  sheet.getColumn(1).width = 18
  sheet.getColumn(2).width = 20
  sheet.getColumn(3).width = 18
  sheet.getColumn(4).width = 18
  sheet.getColumn(5).width = 18
  sheet.getColumn(6).width = 18

  return rowIndex + 1
}

const writeRow = (sheet: ExcelJS.Worksheet, rowIndex: number, wagon: Wagon, commande: Commande | undefined): number => {
  sheet.getRow(rowIndex).height = 16
  sheet.getRow(rowIndex).alignment = alignementCenter
  sheet.getRow(rowIndex).font = fontBody
  for (const cell of getBodyCells(rowIndex, wagon, commande)) {
    sheet.getCell(cell.name).value = cell.value
    sheet.getCell(cell.name).border = borderBody
  }
  return rowIndex + 1
}

const getBodyCells = (rowIndex: number, wagon: Wagon, commande: Commande | undefined) => {
  return [{
    name: 'A' + rowIndex,
    ref: 'name',
    value: commande?.name || '',
  }, {
    name: 'B' + rowIndex,
    ref: 'id',
    value: idToString(wagon.id || ''),
  }, {
    name: 'C' + rowIndex,
    ref: 'owner',
    value: wagon.owner || '',
  }, {
    name: 'D' + rowIndex,
    ref: 'typeAndSet',
    value: wagon.typeAndSet || '',
  }, {
    name: 'E' + rowIndex,
    ref: 'railway',
    value: wagon.railway || '',
  }, {
    name: 'F' + rowIndex,
    ref: 'area',
    value: wagon.area || '',
  }]
}
