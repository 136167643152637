import { defineStore } from 'pinia'
import { apiHandler, ServerResponse } from '@/api/ApiHandler'
import { pushItem, pushItems, removeItem, setItems, UpdateFieldPayload } from '.'
import { Toaster } from '@/common/Toaster'
import { Piece } from '@/model/Piece'

export interface StoreStatePiece {
  pieces: Piece[]
  currentId: number | undefined
}

export const useStorePiece = defineStore('piece', {
  state: (): StoreStatePiece => ({
    pieces: [],
    currentId: undefined,
  }),
  getters: {
    piece: (state) => state.pieces.find(c => c.id === state.currentId),
  },
  actions: {
    async getById(
      id?: number,
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'commex/piece/pieceManager.php?search=true',
        { id: id },
      )
      this.pieces = setItems(this.pieces, response.pieces)
      // commit(AmianteStoreMutations.SET_PIECES, response.pieces || [])
      return response
    },
    async getByAmiante(
      id?: number,
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'commex/piece/pieceManager.php?search=true',
        { amianteId: id },
      )
      this.pieces = setItems(this.pieces, response.pieces)
      // commit(AmianteStoreMutations.SET_PIECES, response.pieces || [])
      return response
    },
    async add(
      piece: { amianteId: number },
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'commex/piece/pieceManager.php',
        piece,
      )
      Toaster.toast({
        type: response.success ? 'success' : 'error',
        message: response.message,
      })
      if (response.success) {
        this.pieces = pushItem(this.pieces, response.piece)
        // commit(AmianteStoreMutations.PUSH_PIECE, response.piece || {})
      }
      return response
    },
    async updateField(
      { name, lastValue, value, id }: UpdateFieldPayload,
    ): Promise<ServerResponse> {
      if (lastValue === value || (!value && !lastValue)) return {} as any

      const response = await apiHandler.put(
        'commex/piece/pieceManager.php?field=true',
        { name, value, id },
      )
      Toaster.toast({
        type: response.success ? 'success' : 'error',
        message: response.message,
      })
      if (response.success) {
        this.pieces = pushItem(this.pieces, response.piece)
        // commit(AmianteStoreMutations.UPDATE_PIECE, response.piece || {})
      }
      return response
    },
    async updateAll(
      wagonIds: string[], pieces: Piece[],
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'commex/amiante/amianteManager.php?allpieces=true',
        { wagonIds, pieces },
      )
      if (response.success) {
        this.pieces = pushItems(this.pieces, response.pieces)
      }
      // commit(AmianteStoreMutations.SET_IMAGES, response.images || '')
      return response
    },
    async delete(
      id: number,
    ): Promise<ServerResponse> {
      const response = await apiHandler.delete(
        `commex/piece/pieceManager.php?id=${id}`,
      )
      Toaster.toast({
        type: response.success ? 'success' : 'error',
        message: response.message,
      })
      if (response.success) {
        this.pieces = removeItem(this.pieces, id)
        // commit(AmianteStoreMutations.REMOVE_PIECE, id)
      }
      return response
    },
  },
})
