export interface StoreItem {
  id?: string | number
}

export interface ItemImage {
  id: string | number
  wagonId?: string
  name: string
  base64: string
}
export interface UpdatePayload<T> {
  id: number | string
  data: Partial<T>
}

export interface ImagePayload {
  base64: string
  id: string
  file: File
}

export interface UpdateFieldPayload {
  name: string
  value: unknown
  lastValue: unknown
  id: number
}

export const setItems = <T extends StoreItem>(
  currentItems: T[],
  items: T[] = [],
): T[] => {
  return items
}

export const pushItems = <T extends StoreItem>(
  currentItems: T[],
  items?: T[],
): T[] => {
  if (!items) return currentItems
  for (const item of items) {
    if (currentItems.find((i) => i.id === item.id)) {
      currentItems = currentItems.map((i) => (i.id === item.id ? item : i))
    } else {
      currentItems.push(item)
    }
  }
  return currentItems
}

export const pushItem = <T extends StoreItem>(
  currentItems: T[],
  item?: T,
): T[] => {
  if (!item) return currentItems
  if (currentItems.find((i) => i.id === item.id)) {
    currentItems = currentItems.map((i) => (i.id === item.id ? item : i))
  } else {
    currentItems.push(item)
  }
  return currentItems
}

export const removeItem = <T extends StoreItem>(
  currentItems: T[],
  id?: number | string,
): T[] => {
  return currentItems?.filter((i) => i.id !== id)
}
