<template>
  <div class="base-card">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.base-card {
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgb(211, 211, 211);
}
</style>
