import { ServerResponse, apiHandler } from '@/api/ApiHandler'
import { Toaster } from '@/common/Toaster'
import { downloadFile } from '@/common/util'
import { defineStore } from 'pinia'
import JSZip from 'jszip'
import saveAs from 'file-saver'
import { useStoreAmiante } from './amiante'

export interface StoreStateReport {
  report?: string
  prereport?: string
  prereportanalyse?: string
  reportanalyse?: string
  reportdocumentation?: string
  reportother?: string
  maxReportDownload: number
  currentReportDownload: number
}

export interface PayloadReportSave {
  wagonId: string
  file: File
  base64: string
  name: string
}

export const useStoreReport = defineStore('report', {
  state: (): StoreStateReport => ({
    report: undefined,
    prereport: undefined,
    prereportanalyse: undefined,
    reportanalyse: undefined,
    reportdocumentation: undefined,
    reportother: undefined,
    maxReportDownload: 0,
    currentReportDownload: 0,
  }),
  actions: {
    async get(
      wagonId: string, name: string,
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        `commex/amiante/amianteManager.php?getreport=true`,
        { wagonId, name },
      )
      this.setReport(response.report, name)
      return response
    },
    async save(
      { wagonId, file, base64, name }: PayloadReportSave,
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'commex/amiante/amianteManager.php?savereport=true',
        { wagonId, file, base64, name },
      )
      Toaster.toast({
        type: response.success ? 'success' : 'error',
        message: response.message,
      })
      this.setReport(base64, name)
      // commit(AmianteStoreMutations.SET_REPORT, { base64: base64 || '', name })
      return response
    },
    async delete(
      wagonId: string, name: string,
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'commex/amiante/amianteManager.php?deletereport=true',
        { wagonId, name },
      )
      Toaster.toast({
        type: response.success ? 'success' : 'error',
        message: response.message,
      })
      this.setReport('', name)
      // commit(AmianteStoreMutations.SET_REPORT, { base64: '', name })
      return response
    },
    async saveAll(
      wagonIds: string[], file: File, base64: string, name: string,
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'commex/amiante/amianteManager.php?allreport=true',
        { wagonIds, file, base64, name },
      )
      Toaster.toast({
        type: response.success ? 'success' : 'error',
        message: response.message,
      })
      return response
    },
    async deleteAll(
      wagonIds: string[], name: string,
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'commex/amiante/amianteManager.php?deleteallreport=true',
        { wagonIds, name },
      )
      Toaster.toast({
        type: response.success ? 'success' : 'error',
        message: response.message,
      })
      return response
    },
    setReport(base64: string, name: string): void {
      if (name === 'prereport') {
        this.prereport = base64
      } else if (name === 'report') {
        this.report = base64
      } else if (name === 'prereportanalyse') {
        this.prereportanalyse = base64
      } else if (name === 'reportanalyse') {
        this.reportanalyse = base64
      } else if (name === 'documentation') {
        this.reportdocumentation = base64
      } else if (name === 'reportother') {
        this.reportother = base64
      } else {
        console.error(`${name} is not a report`)
      }
    },
    async downloadAll(wagonIds: string[], name: string): Promise<void> {
      const zip = new JSZip()
      const folder = zip.folder(name)
      let fileCount = 0
      this.currentReportDownload = 0
      this.maxReportDownload = wagonIds.length || 0
      for (const wagonId of wagonIds) {
        const response = await apiHandler.post(
          `commex/amiante/amianteManager.php?getreport=true`,
          { wagonId, name },
        )
        if (response.report) {
          fileCount++
          console.log(`${name}_${wagonId}.pdf`)
          folder?.file(`${wagonId}.pdf`, response.report.replace(/^data:[a-zA-Z0-9\/\.\-\+]+;base64,/, ``), { base64: true })
        }
        this.currentReportDownload++
      }

      if (!fileCount) {
        return Toaster.toast({
          type: 'warning',
          message: `Aucun fichier à télécharger`,
        })
      }

      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, 'reports.zip')

        return Toaster.toast({
          type: 'info',
          message: `Téléchargement de ${fileCount} fichiers`,
        })
      })
    },
    async downloadAllImages(wagonIds: string[]): Promise<void> {
      const amianteStore = useStoreAmiante()
      const zip = new JSZip()
      const folder = zip.folder('images')

      let fileCount = 0
      for (const wagonId of wagonIds) {
        const wagonFolder = folder?.folder(wagonId)
        await amianteStore.getImages(wagonId)

        for (const image of amianteStore.images) {
          if (image) {
            fileCount++
            console.log(`${image.name}`)
            wagonFolder?.file(`${image.name}`, image.base64.replace(/^data:[a-zA-Z0-9\/\.\-\+]+;base64,/, ``), { base64: true })
          }
        }
      }

      if (!fileCount) {
        return Toaster.toast({
          type: 'warning',
          message: `Aucun fichier à télécharger`,
        })
      }

      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, 'images.zip')

        return Toaster.toast({
          type: 'info',
          message: `Téléchargement de ${fileCount} fichiers`,
        })
      })
    },
  },
})
