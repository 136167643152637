import { Toast } from '@/common/Toaster'
import router from '@/router'
import { useStoreAuth } from '../store/auth'

export interface ServerResponse {
  code: number
  data?: unknown
  error?: string | unknown
  success?: boolean
  type?: string
  message?: string
  status?: number
}

class ApiClient {
  // baseUrl = process.env.VUE_APP_API_URL
  // baseUrl = `http://localhost:8888/sygem/api/`
  // baseUrl = `https://testing.sgm-groupe.fr/api/`
  baseUrl =
    process.env.NODE_ENV === 'production' ?
      `https://intranet.sgm-groupe.fr/api/` :
      `http://localhost:8888/sygem/api/`

  get token(): string {
    const storeAuth = useStoreAuth()
    return storeAuth.token
  }

  logout(): void {
    const storeAuth = useStoreAuth()
    storeAuth.logout()
    router.push({ name: 'Login', params: {} })
  }

  lang = 'fr'

  headers = {
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': this.lang,
  }

  async get(path: string) {
    const request = {
      method: 'GET',
      headers: {
        ...this.headers,
        Authorization: this.token,
        'Accept-Language': this.lang,
      },
    }

    this.logRequest(path, request)

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async response => {
        const json = await response.json()
        this.logResponse(json, path, 'GET')
        if (json.code === -1) {
          this.logout()
        }
        return json
      })
      .catch(error => {
        return this.handleError(error, path, 'GET')
      })
  }

  async post(path: string, body: unknown = {}) {
    const request = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        ...this.headers,
        Authorization: this.token,
        'Accept-Language': this.lang,
      },
    }

    this.logRequest(path, request)

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async (response: Response) => {
        const json = await response.json()
        this.logResponse(json, path, 'POST')
        if (json.code === -1) {
          this.logout()
        }
        return json
      })
      .catch(error => {
        return this.handleError(error, path, 'POST')
      })
  }

  async put(path: string, body: unknown = {}) {
    const request = {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        ...this.headers,
        Authorization: this.token,
        'Accept-Language': this.lang,
      },
    }

    this.logRequest(path, request)

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async response => {
        console.log(response)
        const json = await response.json()
        this.logResponse(json, path, 'PUT')
        if (json.code === -1) {
          this.logout()
        }
        return json
      })
      .catch(error => {
        return this.handleError(error, path, 'PUT')
      })
  }

  async delete(path: string, body: unknown = {}) {
    const request = {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: {
        ...this.headers,
        Authorization: this.token,
        'Accept-Language': this.lang,
      },
    }

    this.logRequest(path, request)

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async response => {
        const json = await response.json()
        this.logResponse(json, path, 'DELETE')
        if (json.code === -1) {
          this.logout()
        }
        return json
      })
      .catch(error => {
        return this.handleError(error, path, 'DELETE')
      })
  }

  handleError(error: Error, path: string, method: string): Toast {
    if (error.message.match(`JSON.parse`)) {
      console.log(`Commex API error : cannotConnectToServer` + ` : ${method} ${this.baseUrl}${path}`)
    }

    return { type: 'error', message: `Une erreur s'est produite sur le serveur.` }
  }

  handleResponse(response: Response) {
    console.log(response)
    return response.json()
  }

  logRequest(url: string, header: RequestInit) {
    console.debug(`Server call on ${this.baseUrl}${url}`, header)
  }

  logResponse(json: ServerResponse, url: string, method: string) {
    if (process.env.VUE_APP_MODE === 'dev') {
      const response = { ...json }
      if (
        response &&
        response.data &&
        (response.data as unknown[]).length > 200
      ) {
        response.data = `${response.data}`.slice(0, 100)
      }
      console.debug(
        `Server call on ${method} ${url} and response with : `,
        response,
      )
    }
  }
}

export const apiHandler = new ApiClient()
