import { defineStore } from 'pinia'
import { apiHandler, ServerResponse } from '@/api/ApiHandler'
import { Commande } from '@/model/Commande'
import { pushItem, pushItems } from '.'

export interface StoreStateCommande {
  commandes: Commande[]
  currentId: number | undefined
}

export const useStoreCommande = defineStore('commande', {
  state: (): StoreStateCommande => ({
    commandes: [],
    currentId: undefined,
  }),
  getters: {
    commande: (state) => state.commandes.find(c => c.id === state.currentId),
  },
  actions: {
    async get(
      id?: number,
    ): Promise<ServerResponse> {
      if (!id) return { type: 'warning', message: 'No id provided', code: -1 }
      const response = await apiHandler.post(
        'commex/commande/commandeManager.php?search=true',
        { id },
      )
      const commandes = response.commandes
      if (commandes.length) {
        this.commandes = pushItem(this.commandes, commandes[0])
      }
      return response
    },
    async search(
      data = {},
    ): Promise<ServerResponse> {
      const response = await apiHandler.post(
        'commex/commande/commandeManager.php?search=true',
        { ...data },
      )
      this.commandes = pushItems(this.commandes, response.commandes)
      return response
    },
    async close(
      id?: number,
    ): Promise<ServerResponse> {
      if (!id) return { type: 'warning', message: 'No id provided', code: -1 }
      const response = await apiHandler.post(
        'commex/commande/commandeManager.php?close=true',
        id,
      )
      this.commandes = pushItem(this.commandes, response.commande)
      return response
    },
    async open(
      id?: number,
    ): Promise<ServerResponse> {
      if (!id) return { type: 'warning', message: 'No id provided', code: -1 }
      const response = await apiHandler.post(
        'commex/commande/commandeManager.php?open=true',
        id,
      )
      this.commandes = pushItem(this.commandes, response.commande)
      return response
    },
  },
})
